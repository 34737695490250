import React from "react";
import Footer from "../../Components/Footer/footer";
import {  useLocation, useNavigate } from "react-router";

const Paymentsuccess = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const query = new URLSearchParams(location.search);
    const name = query.get('name');
    const contact = query.get('contact');
    const paymentid = query.get('paymentid');
    const status = query.get('status');
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                YOUR PAYMENT HAS BEEN COMPLETED SUCCESSFULLY
              </h2>
              <p className="section-description mb-4 p-0">
                We’re excited to let you know that your payment was successful.
                Check your email for a receipt and more details about your
                order.
              </p>
            </div>

            <div className="card card-md">
              <div className="card-header">
                <h1 className="card-title">Payment Details</h1>
              </div>
              <div className="card-body">
                <div className="border border-1 rounded-3 my-2 ms-5 p-5" >
                  <p className="fw-bold">Name: <span className=" fw-normal">{name}</span></p>
                  {/* <p className="fw-bold">Email:  <span className="fw-normal">{email}</span> </p> */}
                  <p className="fw-bold">Phone: <span className="fw-normal">{contact}</span> </p>
                  <p className="fw-bold">Payment Id: <span className="fw-normal">{paymentid}</span> </p>
                  <p className="fw-bold">Payment Status: <span className="fw-normal">{status}</span> </p>
                  <div className="">
                  <button className="btn btn-primary" onClick={()=> navigate("/")}>Go to Home</button>
                  </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Paymentsuccess;
