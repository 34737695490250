import React, { useEffect, useState } from "react";
import Logo from "../../assets/hire48logoHorizontal.png";
import { MdFindInPage } from "react-icons/md";
import { BsPersonWorkspace } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetterOfEachWord } from "../Functions/word.fun";
import { logout } from "../../Redux/User/login_reducer";
import { candidate, company, recruiter, superAdmin } from "../../Config/config";
const getNavItemByRole = (role) => {
  if (role === superAdmin) {
    return [
      "Internal",
      "Find Work",
      "Free Resume Builder",
      "Browse Top Companies",
      "AI Interview Cracker",
      "Get Skill Badged",
      "Find Talent",
      "Onboard Hiring Partner",
      // "Browse Top Candidate",
      "Post Jobs",
      "View Jobs",
      "Job Board",
      "Post Jobs",
      "Post Contest",
      "Applicants",
    ];
  }
  if (role === candidate) {
    return [
      "Find Work",
      "Free Resume Builder",
      "Browse Top Companies",
      // "AI Interview Cracker",
      "Get Skill Badged",
    ];
  }
  if (role === company) {
    return [
      "Find Talent",

      // "Browse Top Candidate",
      "Post Jobs",
      "View Jobs",
      "Job Board",
      "My Scheduled Interview",
    ];
  }
  if (role === recruiter) {
    return [
      "Internal",
      // "Browse Top Candidate",
      "Post Jobs",
      "Post Contest",
      "Applicants",
      "All Candidates",
      "List All Companies",
      "All Onboarding Request",
      "All Shortlist Request",
      "All Payments"
    ];
  } else {
    return [];
  }
};
const Header = () => {
  const location = useLocation();
  const endpoint = location.pathname;
  const [activeNavItem, setActiveNavItem] = useState("");
  const store = useSelector((store) => store.userLogin);
  const role = useSelector((store) => store.userLogin?.user?.Role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navItem, setNavItem] = useState([]);
  useEffect(() => {
    if (store.isLoggedIn && role) {
      setNavItem(getNavItemByRole(role));
    }
    if (!store.isLoggedIn) {
      setNavItem([]);
    }
  }, [role, store]);

  useEffect(() => {
    if (
      endpoint.includes("/top-hires") ||
      endpoint.includes("/resume-builder") ||
      endpoint.includes("/ai-interview-cracker") ||
      endpoint.includes("/learn-soft-skills") ||
      endpoint.includes("/get-skill-badges")
    ) {
      setActiveNavItem("FindWork");
    } else if (
      endpoint.includes("/candidate-comparison") ||
      endpoint.includes("/create-jobs") ||
      endpoint.includes("/job-board")
    ) {
      setActiveNavItem("FindTalent");
    } else if (
      endpoint.includes("/onboard-hiring-partner") ||
      endpoint.includes("/post-contest") ||
      endpoint.includes("/create-jobs") ||
      endpoint.includes("/job-details") ||
      endpoint.includes("/employer-onboarding") ||
      endpoint.includes("/schedule-interview") ||
      endpoint.includes("/view-jobs") ||
      endpoint.includes("/candidate-comparison") ||
      endpoint.includes("/job-board") ||
      endpoint.includes("/jobs-list") ||
      endpoint.includes("/my-scheduled-interview")||
      endpoint.includes("/shortlist-request") ||
      endpoint.includes("/all-payments") 
    ) {
      setActiveNavItem("Internal");
    }
  }, [endpoint]);

  const closeNavbar = () => {
    const navbarMenu = document.getElementById("navbar-menu");
    if (navbarMenu && navbarMenu.classList.contains("show")) {
      document.getElementById("navbar-toggler").click();
    }
  };
  return (
    <>
      <header className="navbar navbar-expand-md d-print-none">
        <div className="container-xxl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
            aria-controls="navbar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" id="navbar-toggler"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <span onClick={() => navigate("/")} className="cursor-pointer">
              <img
                src={Logo}
                width="210"
                height="42"
                alt="Tabler"
                className="navbar-brand-image"
              />
            </span>
          </h1>
          <div className="navbar-nav flex-row order-md-last">
            {store.isLoggedIn && (
              <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link d-flex lh-1 text-reset p-0"
                  data-bs-toggle="dropdown"
                  aria-label="Open user menu"
                >
                  <span className="avatar avatar-sm">
                    <img src={store?.user?.profilePicture} alt="" srcSet="" />
                  </span>
                  <div className="d-none d-xl-block ps-2">
                    <div>
                      {capitalizeFirstLetterOfEachWord(store?.user?.Name)}
                    </div>
                    <div className="mt-1 small text-secondary">
                      {capitalizeFirstLetterOfEachWord(store?.user?.Role)}
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <button
                    onClick={() => navigate("/my-profile")}
                    className="dropdown-item"
                  >
                    My Profile
                  </button>
                  {store?.user?.Role !== company &&
                    store?.user?.Role !== recruiter && (
                      <button
                        onClick={() => navigate("/my-applications")}
                        className="dropdown-item"
                      >
                        My Applications
                      </button>
                    )}
                  <button
                    onClick={() => {
                      dispatch(logout());
                      navigate("/");
                    }}
                    className="dropdown-item"
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}
            {!store.isLoggedIn && (
              <div className="nav-item">
                <div className="flex-row">
                  <button
                    onClick={() => navigate("/account-login")}
                    className="btn btn-pill btn-indigo"
                  >
                    Account Login
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="  d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
              <ul className="navbar-nav">
                {navItem.includes("Find Work") && (
                  <li
                    className={
                      activeNavItem === "FindWork"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-findwork"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Find Work</span>
                    </a>
                    <div
                      onClick={() => {
                        document.getElementById("k-findwork").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu "
                    >
                      {navItem.includes("Browse Top Companies") && (
                        <NavLink
                          to={"/top-hires"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Browse Top Companies
                        </NavLink>
                      )}

                      {navItem.includes("Free Resume Builder") && (
                        <NavLink
                          to={"/resume-builder"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Free Resume Builder
                        </NavLink>
                      )}

                      {navItem.includes("AI Interview Cracker") && (
                        <NavLink
                          to={"/ai-interview-cracker"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          {" "}
                          AI Interview Cracker
                        </NavLink>
                      )}

                      {/* {  navItem.includes('AI Interview Cracker') &&<NavLink
                      to={"/learn-soft-skills"}
                      className={({ isActive }) =>
                        isActive ? "dropdown-item active" : "dropdown-item"
                      }
                    >
                      Learn Soft Skills
                    </NavLink>} */}

                      {navItem.includes("Get Skill Badged") && (
                        <NavLink
                          to={"/get-skill-badges"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Get Skill Badged
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Find Talent") && (
                  <li
                    className={
                      activeNavItem === "FindTalent"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-findtalent"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <MdFindInPage size={20} />
                      </span>
                      <span className="nav-link-title">Find Talent</span>
                    </a>
                    <div
                      onClick={() => {
                        document.getElementById("k-findtalent").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {/* {navItem.includes("Browse Top Candidate") && (
                        <NavLink
                          to={"/top-candidates"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Browse Top Candidate
                        </NavLink>
                      )} */}

                      {navItem.includes("Post Jobs") && (
                        <NavLink
                          to={"/create-jobs"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Post Jobs
                        </NavLink>
                      )}

                      {navItem.includes("Job Board") && (
                        <NavLink
                          to={"/job-board"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Job Board
                        </NavLink>
                      )}
                      {navItem.includes("My Scheduled Interview") && (
                        <NavLink
                          to={"/my-scheduled-interview"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Scheduled Interview
                        </NavLink>
                      )}
                    </div>
                  </li>
                )}
                {navItem.includes("Internal") && (
                  <li
                    className={
                      activeNavItem === "Internal"
                        ? "nav-item active dropdown"
                        : "nav-item dropdown"
                    }
                  >
                    <button
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      role="button"
                      aria-expanded="false"
                    >
                      <span
                        id="k-internal"
                        className="nav-link-icon d-md-none d-lg-inline-block"
                      >
                        <BsPersonWorkspace size={18} />
                      </span>
                      <span className="nav-link-title">Internal</span>
                    </button>
                    <div
                      onClick={() => {
                        document.getElementById("k-internal").click();
                        closeNavbar();
                      }}
                      className="dropdown-menu"
                    >
                      {navItem.includes("Onboard Hiring Partner") && (
                        <NavLink
                          to={"/onboard-hiring-partner"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Onboard Hiring Partner
                        </NavLink>
                      )}
                      {navItem.includes("Post Jobs") && (
                        <NavLink
                          to={"/create-jobs"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Post Jobs
                        </NavLink>
                      )}
                      {navItem.includes("Post Contest") && (
                        <NavLink
                          to={"/post-contest"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Post Contest
                        </NavLink>
                      )}
                      {navItem.includes("Job Board") && (
                        <NavLink
                          to={"/job-board"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Job Board
                        </NavLink>
                      )}

                      {navItem.includes("Applicants") && (
                        <NavLink
                          to={"/jobs-list"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Jobs List
                        </NavLink>
                      )}
                      {navItem.includes("Booked Demo List") && (
                        <NavLink
                          to={"/book-demo-list"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Booked Demo List
                        </NavLink>
                      )}
                      {navItem.includes("Contact Us List") && (
                        <NavLink
                          to={"/contactus-list"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Contact Us List
                        </NavLink>
                      )}

                      {navItem.includes("All Candidates") && (
                        <NavLink
                          to={"/list-allcandidates"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Candidates
                        </NavLink>
                      )}

                      {navItem.includes("All Companies") && (
                        <NavLink
                          to={"/listed-companies"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          List All Companies
                        </NavLink>
                      )}
                      {navItem.includes("All Onboarding Request") && (
                        <NavLink
                          to={"/onboarding-request"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Onboarding Request
                        </NavLink>
                      )}

                    {navItem.includes("All Shortlist Request") && (
                        <NavLink
                          to={"/shortlist-request"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          Shortlist Request
                        </NavLink>
                      )}


                        {navItem.includes("All Payments") && (
                        <NavLink
                          to={"/all-payments"}
                          className={({ isActive }) =>
                            isActive ? "dropdown-item active" : "dropdown-item"
                          }
                        >
                          All Payments
                        </NavLink>
                      )}     
                       

                 
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
