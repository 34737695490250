import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import loginReducer from "./User/login_reducer";
import paginationFilterSlice from "./Pagination & Filters/filtersReducer";
import allCandidatesFilterSlice from "./Pagination & Filters/allCandidateFiltersReducer";
import allCompaniesFilterSlice from "./Pagination & Filters/allCompaniesFiltersReducer";
import allShortlistRequestFilterSlice from "./Pagination & Filters/allShortlistRequestFiltersReducer";
import allPaymentsFilterSlice from "./Pagination & Filters/allPaymentsFiltersReducer";
export const store = configureStore({
  reducer: {
    userLogin: loginReducer,
    filters: paginationFilterSlice,
    allCandidatesFilter: allCandidatesFilterSlice,
    allCompaniesFilter: allCompaniesFilterSlice,
    allShortlistRequestFilter: allShortlistRequestFilterSlice,
    allPaymentsFilter:allPaymentsFilterSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
