import React from "react";
import { Route, Routes } from "react-router-dom";
import PostContest from "../Pages/Find Talent/post_contest";
import OnboardHiringPartner from "../Pages/Find Talent/onboard_hiring_partner";
import BrowesTopHires from "../Pages/Find Work/browse_top_hires";
import FreeResumeBuilder from "../Pages/Find Work/free_resume_builder";
import AiInterviewCracker from "../Pages/Find Work/ai_interview_cracker";
import GetSkillBadges from "../Pages/Find Work/get_skill_badges";
import VerifiedProfiles from "../Pages/Why Hire48/verified_profile";
import HireAtLowPrice from "../Pages/Why Hire48/hire_at_low_price";
import FreeAcountManager from "../Pages/Why Hire48/free_acount_manager";
import HireWithoutBreakingBank from "../Pages/Why Hire48/hire_without_breaking_bank";
import Free3MonthsReplacement from "../Pages/Why Hire48/free_3_months_replacement";
import ContactUs from "../Pages/Footer Pages/contact_us";
import HowWeWork from "../Pages/Footer Pages/how_we_work";
import AboutUs from "../Pages/Footer Pages/about_us";
import SuccessStories from "../Pages/Footer Pages/success_stories";
import GrievanceRedressal from "../Pages/Footer Pages/grievance_redressal";
import TermAndConditions from "../Pages/Footer Pages/terms_&_conditions";
import PrivacyPolicy from "../Pages/Footer Pages/privacy_policy";
import FAQs from "../Pages/Footer Pages/faq";
import Pricing from "../Pages/Footer Pages/pricing";
import ReferAndEarn from "../Pages/Footer Pages/refer_&_earn";
import Dashboard from "../Pages/Dashboard/dashboard";
import PageNotFound from "../Pages/PageNotFound/page_not_found";
import TestDetails from "../Pages/Find Work/Get Skill Badges/test_details";
import GiveTest from "../Pages/Find Work/Get Skill Badges/give_test";
import AddNewJobs from "../Pages/Find Talent/Post Jobs/add_new_jobs";
import JobDetails from "../Pages/Find Talent/Post Jobs/job_details";
import EmployerOnbord from "../Pages/Find Talent/Onboard HIring Partner/employer_onbord";
import ViewJobs from "../Pages/Find Work/Browse Top Hires/view_jobs";
import CandidateCompare from "../Pages/Find Talent/Job Board/candidate_comparison";
import BookDemoList from "../Pages/Internals/book_demo_list";
import JobBoard from "../Pages/Find Talent/job_board";
import CandidateListing from "../Pages/Find Talent/Job Board/candidate_listing";
import BrowesTopCandidate from "../Pages/Find Work/browse_top_candidate";
import AccountLogin from "../Pages/User/account-login";
import AccountSignup from "../Pages/User/account-signup";
import EditTests from "../Pages/Find Talent/Post Contest/edit_tests";
import Alltestdata from "../Pages/Find Talent/Post Contest/all_test_data";
import AiInterview from "../Pages/Find Work/AI Interview Crackers/ai_interview";
import AllJobs from "../Pages/Internals/all_jobs";
import ResetPassword from "../Pages/User/reset-password";
import { candidate, company, recruiter, superAdmin } from "../Config/config";
import { useSelector } from "react-redux";
import OnboardingRequest from "../Pages/Internals/onboarding_request";
import ListedCompanies from "../Pages/Internals/listed_companies";
import Myapplications from "../Pages/Dashboard/my_applications";
import Allcandidatelist from "../Pages/Internals/all_candidate_list";
import Myprofile from "../Pages/Dashboard/my_profile";
import Candidate from "../Pages/Find Work/Resume Builder/Cadidate";
import JobApplications from "../Pages/Find Talent/Job Board/job_applications";
import ContactUsList from "../Pages/Internals/contact_us_list";
import Myscheduleinterview from "../Pages/Find Talent/my_scheduled_interview";
import CandidateDetails from "../Pages/Find Work/Resume Builder/candidate_details";
import AllAppliedJobs from "../Pages/Internals/All Candidates/applied_jobs";
import Companydetails from "../Pages/Internals/company_details";
import Shortlistrequest from "../Pages/Internals/shortlist_request";
import AppliedCandidateResume from "../Pages/Internals/All Candidates/applied_candidate_resume";
import VerifiedSkills from "../Pages/Why Hire48/verified_skills";
import TakeUpTheCourse from "../Pages/Why Hire48/take_up_course";
import Paymentfailure from "../Pages/Instamojo/payment_failure";
import Paymentsuccess from "../Pages/Instamojo/payment_success";
import Payment from "../Pages/Instamojo/payment";
import Allpayments from "../Pages/Internals/all_payments";
const routes = [
  {
    auth: [superAdmin, candidate, company],
    path: "/userprofile-contest",
    ele: <Candidate />,
  },

  {
    auth: [superAdmin, candidate, company],
    path: "/userprofile-contest",
    ele: <Candidate />,
  },

  {
    auth: [superAdmin, recruiter],
    path: "/post-contest",
    ele: <PostContest />,
  },

  {
    auth: [superAdmin, recruiter],
    path: "/edit-contest/:id",
    ele: <EditTests />,
  },

  {
    auth: [superAdmin],
    path: "/top-candidates",
    ele: <BrowesTopCandidate />,
  },
  {
    auth: [superAdmin, candidate, recruiter],
    path: "/resume-builder",
    ele: <FreeResumeBuilder />,
  },

  {
    auth: [superAdmin, candidate, recruiter],
    path: "/ai-interview",
    ele: <AiInterview />,
  },
  // {
  //   auth: [superAdmin, candidate, recruiter, company],
  //   path: "/learn-soft-skills",
  //   ele: <LearnSoftSkills />,
  // },

  {
    auth: [superAdmin, candidate, recruiter],
    path: "/get-skill-badges/take-test/:id",
    ele: <GiveTest />,
  },

  {
    auth: [superAdmin, candidate, recruiter],
    path: "/my-applications",
    ele: <Myapplications />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/applications/:id",
    ele: <JobApplications />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/company-details/:id",
    ele: <Companydetails />,
  },
  {
    auth: [superAdmin, candidate, recruiter, company],
    path: "/my-profile",
    ele: <Myprofile />,
  },

  {
    auth: [superAdmin, recruiter, company],
    path: "/create-jobs",
    ele: <AddNewJobs />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/edit-jobs/:id",
    ele: <AddNewJobs />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/job-details/:id",
    ele: <JobDetails />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/job-board",
    ele: <JobBoard />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/candidate-listing/:id",
    ele: <CandidateListing />,
  },

  {
    auth: [superAdmin, candidate, recruiter, company],
    path: "/view-jobs/:id",
    ele: <ViewJobs />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/candidate-comparison/:id",
    ele: <CandidateCompare />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/book-demo-list",
    ele: <BookDemoList />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/contactus-list",
    ele: <ContactUsList />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/shortlist-request",
    ele: <Shortlistrequest />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/jobs-list",
    ele: <AllJobs />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/jobs-list/:employerId",
    ele: <AllJobs />,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/applied-jobs/:candidateId",
    ele: <AllAppliedJobs />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/onboarding-request",
    ele: <OnboardingRequest />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/listed-companies",
    ele: <ListedCompanies />,
  },

  {
    auth: [superAdmin, recruiter, company],
    path: "/list-allcandidates",
    ele: <Allcandidatelist />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/my-scheduled-interview",
    ele: <Myscheduleinterview />,
  },
  {
    auth: [superAdmin, candidate, recruiter],
    path: "/all-test",
    ele: <Alltestdata></Alltestdata>,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/onboard-hiring-partner",
    ele: <OnboardHiringPartner></OnboardHiringPartner>,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/view-candidate/:id",
    ele: <AppliedCandidateResume></AppliedCandidateResume>,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/candidate-details/:id",
    ele: <CandidateDetails></CandidateDetails>,
  },
  {
    auth: [superAdmin, recruiter],
    path: "/all-payments",
    ele: <Allpayments/>,
  },

  {
    auth: [superAdmin, recruiter, company],
    path: "/payment",
    ele: <Payment />,
  },
  {
    auth: [superAdmin, recruiter,company],
    path: "/payment-failed",
    ele: <Paymentfailure />,
  },
  {
    auth: [superAdmin, recruiter, company],
    path: "/payment-success",
    ele: <Paymentsuccess />,
  },
];
const AllRoutes = () => {
  const store = useSelector((store) => store?.userLogin?.user);
  const filteredRoutes = routes.filter((route) =>
    route.auth.includes(store?.Role)
  );
  return (
    <Routes>
      {filteredRoutes.map((route) => (
        <Route path={route.path} key={route.path} element={route.ele} />
      ))}
      <Route path="/account-login" element={<AccountLogin />} />
      <Route path="/account-signup" element={<AccountSignup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/employer-onboarding" element={<EmployerOnbord />} />
      {store?.Role === company ? (
        <Route path="/" element={<OnboardHiringPartner />} />
      ) : (
        <Route path="/" element={<Dashboard />} />
      )}

      <Route path="/terms-and-conditions" element={<TermAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/refer-and-earn" element={<ReferAndEarn />} />
      <Route path="/low-price-hire" element={<HireAtLowPrice />} />
      <Route path="/verified-profiles" element={<VerifiedProfiles />} />
      <Route path="/verified-skills" element={<VerifiedSkills />} />
      <Route path="/free-account-manager" element={<FreeAcountManager />} />
      <Route
        path="/hire-without-breaking-bank"
        element={<HireWithoutBreakingBank />}
      />
      <Route
        path="/free-3monts-replacement"
        element={<Free3MonthsReplacement />}
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/how-we-work" element={<HowWeWork />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/success-stories" element={<SuccessStories />} />
      <Route path="/grievance-redressal" element={<GrievanceRedressal />} />
      <Route path="/course-purchase" element={<TakeUpTheCourse />} />

      <Route path="/ai-interview-cracker" element={<AiInterviewCracker />} />
      <Route path="/top-hires" element={<BrowesTopHires />} />

      <Route path="/get-skill-badges" element={<GetSkillBadges />} />
      <Route path="/get-skill-badges/:id" element={<TestDetails />} />
 
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AllRoutes;
